import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./UserForm.css";
import emailjs from "emailjs-com";
import { useNavigate } from "react-router-dom";
import leftImage from "./1.png"; // Adjust the path to your left image
import rightImage from "./2.png"; // Adjust the path to your right image
import { ClipLoader } from "react-spinners"; // Import the loading spinner component

const firebaseConfig = {
  apiKey: "AIzaSyBDrcE1MF2CTwEVLG7gu6YOX7ZPvJjZxDc",
  authDomain: "sunshine-85b93.firebaseapp.com",
  projectId: "sunshine-85b93",
  storageBucket: "sunshine-85b93",
  messagingSenderId: "842986701588",
  appId: "1:842986701588:web:c4d8071c2ff699a9007801",
  measurementId: "G-9P5TYYNJT2",
};

firebase.initializeApp(firebaseConfig);

const UserForm = () => {
  const [formData, setFormData] = useState({
    FullName: "",
    Email: "",
    ContactNo: "",
    Address: "",
    DealerName: "",
    InvoiceNumber: "",
    LittmannModel: "",
    Color: "",
    SerialNumber: "",
    selectedDate: null,
    honeypot: "", // Honeypot field
  });

  const [emailExists, setEmailExists] = useState(false);
  const [userOrder, setUserOrder] = useState(null);
  const [loading, setLoading] = useState(false); // State to manage loading status
  const navigate = useNavigate(); // Initialize useNavigate hook

  useEffect(() => {
    if (formData.Email) {
      checkEmailExists();
    }
  }, [formData.Email]); // Check email existence whenever email field changes

  const checkEmailExists = async () => {
    const db = firebase.firestore();
    const snapshot = await db
      .collection("user")
      .where("Email", "==", formData.Email)
      .get();
    setEmailExists(!snapshot.empty);
  };

  const getUserOrder = async () => {
    const db = firebase.firestore();
    const snapshot = await db
      .collection("user")
      .orderBy("UserOrder", "desc")
      .limit(1)
      .get();
    if (snapshot.empty) {
      return 1;
    } else {
      return snapshot.docs[0].data().UserOrder + 1;
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      selectedDate: date,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check honeypot field
    if (formData.honeypot) {
      alert("Bot submission detected!");
      return;
    }

    if (emailExists) {
      alert("Email already exists in the database!");
      return;
    }

    setLoading(true); // Set loading to true when form is submitted

    const db = firebase.firestore();
    try {
      const order = await getUserOrder();
      setUserOrder(order);

      // Generate a unique ID for the document
      const docRef = await db.collection("user").doc();

      // Add data to Firestore with the generated ID
      await docRef.set({
        ID: docRef.id, // Assign the generated ID to the 'ID' field
        FullName: formData.FullName,
        Email: formData.Email,
        ContactNo: formData.ContactNo,
        Address: formData.Address,
        DealerName: formData.DealerName,
        InvoiceNumber: formData.InvoiceNumber,
        LittmannModel: formData.LittmannModel,
        Color: formData.Color,
        SerialNumber: formData.SerialNumber,
        Date: formData.selectedDate,
        UserOrder: order,
      });

      // Send email with generated ID
      await emailjs.send(
        "service_se2i8og",
        "template_cvph6jb",
        {
          to_email: formData.Email,
          full_name: formData.FullName,
          contact_no: formData.ContactNo,
          address: formData.Address,
          dealer_name: formData.DealerName,
          invoice_no: formData.InvoiceNumber,
          littmann_model: formData.LittmannModel,
          color: formData.Color,
          serial_no: formData.SerialNumber,
          date: formData.selectedDate,
          generated_id: docRef.id,
        },
        "jiD3gSghzufZrsvBk"
      );

      // Reset form data
      setFormData({
        FullName: "",
        Email: "",
        ContactNo: "",
        Address: "",
        DealerName: "",
        InvoiceNumber: "",
        LittmannModel: "",
        Color: "",
        SerialNumber: "",
        selectedDate: null,
        honeypot: "",
      });

      // Redirect to another page
      navigate("/thankyou");
    } catch (error) {
      console.error("Error adding document: ", error);
    } finally {
      setLoading(false); // Set loading to false after form submission
    }
  };

  return (
    <div className="model-form-container">
      <div className="header-images">
        <img src={leftImage} alt="Left" className="left-image" />
        <img src={rightImage} alt="Right" className="right-image" />
      </div>
      <h2>Warranty Card Validation Form</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Full Name</label>
          <input
            type="text"
            name="FullName"
            value={formData.FullName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            name="Email"
            value={formData.Email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Contact Number</label>
          <input
            type="tel"
            name="ContactNo"
            value={formData.ContactNo}
            onChange={handleChange}
            required
            pattern="[0-9]{10}"
            title="Please enter a 10-digit contact number"
          />
        </div>
        <div className="form-group">
          <label>Address</label>
          <input
            type="text"
            name="Address"
            value={formData.Address}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Dealer Name</label>
          <input
            type="text"
            name="DealerName"
            value={formData.DealerName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Invoice Number</label>
          <input
            type="text"
            name="InvoiceNumber"
            value={formData.InvoiceNumber}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Littmann Model Purchased</label>
          <input
            type="text"
            name="LittmannModel"
            value={formData.LittmannModel}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Color</label>
          <input
            type="text"
            name="Color"
            value={formData.Color}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Serial Number</label>
          <input
            type="text"
            name="SerialNumber"
            value={formData.SerialNumber}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Date</label>
          <DatePicker
            className="datepicker"
            selected={formData.selectedDate}
            onChange={handleDateChange}
            required
          />
        </div>
        {/* Honeypot field */}
        <div className="form-group" style={{ display: "none" }}>
          <label>Leave this field empty</label>
          <input
            type="text"
            name="honeypot"
            value={formData.honeypot}
            onChange={handleChange}
          />
        </div>
        <button type="submit" disabled={loading}>
          Submit
        </button>
        <div className="center-loader">
          <ClipLoader size={30} color={"#123abc"} loading={loading} />
        </div>
      </form>
    </div>
  );
};

export default UserForm;
