import React from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import welcomeImage from "./1.png"; // Adjust the path to your image
import newImage from "./2.png"; // Adjust the path to your new image
import "./Welcome.css";

Modal.setAppElement("#root"); // This line is necessary for accessibility

const Welcome = () => {
  const navigate = useNavigate();

  const handleProceedClick = () => {
    navigate("/register");
  };

  return (
    <Modal
      isOpen={true}
      className="welcome-modal"
      overlayClassName="welcome-overlay"
    >
      <div className="welcome-container">
        <div className="welcome-header">
          <img src={welcomeImage} alt="littmann_logo" className="welcome-image" />
          <img src={newImage} alt="sunshine_logo" className="new-image" />
        </div>
        <h1>
          Welcome to Sunshine Health Care <br />
          Warranty Card Validation Portal
        </h1>
        <p>To proceed to the registration, click the button below.</p>
        <button onClick={handleProceedClick}>
          ONLINE WARRANTY REGISTRATION
        </button>
      </div>
    </Modal>
  );
};

export default Welcome;
