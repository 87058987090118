import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import * as XLSX from "xlsx";
import "./Dataview.css";

const UserData = () => {
  const [userData, setUserData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [filterMonth, setFilterMonth] = useState("");
  const [filterUserId, setFilterUserId] = useState("");
  const updateModalRef = useRef(null);

  useEffect(() => {
    if (!firebase.apps.length) {
      firebase.initializeApp({
        apiKey: "AIzaSyBDrcE1MF2CTwEVLG7gu6YOX7ZPvJjZxDc",
        authDomain: "sunshine-85b93.firebaseapp.com",
        projectId: "sunshine-85b93",
        storageBucket: "sunshine-85b93.appspot.com",
        messagingSenderId: "842986701588",
        appId: "1:842986701588:web:c4d8071c2ff699a9007801",
        measurementId: "G-9P5TYYNJT2",
      });
    }

    fetchData();
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterMonth, filterUserId]);

  const fetchData = async () => {
    try {
      const db = firebase.firestore();
      let query = db.collection("user");

      if (filterUserId) {
        query = query.where("ID", "==", filterUserId);
      }
      if (filterMonth) {
        const [year, month] = filterMonth.split("-");
        const startOfMonth = new Date(year, month - 1, 1);
        const endOfMonth = new Date(year, month, 0, 23, 59, 59, 999);
        query = query
          .where("Date", ">=", firebase.firestore.Timestamp.fromDate(startOfMonth))
          .where("Date", "<=", firebase.firestore.Timestamp.fromDate(endOfMonth));
      }

      const data = await query.get();
      const formattedData = data.docs.map((doc) => {
        const {
          UserOrder,
          ID,
          FullName,
          Email,
          ContactNo,
          Address,
          DealerName,
          InvoiceNumber,
          LittmannModel,
          Color,
          SerialNumber,
          Date: userDate,
          
        } = doc.data();
        const formattedDate =
          userDate instanceof firebase.firestore.Timestamp
            ? new Date(userDate.seconds * 1000).toISOString().substring(0, 10)
            : userDate;
        return {
          UserOrder,

          id: doc.id,
          ID,
          FullName,
          Email,
          ContactNo,
          Address,
          DealerName,
          InvoiceNumber,
          LittmannModel,
          Color,
          SerialNumber,
          Date: formattedDate,
          
        };
      });

      formattedData.sort((a, b) => a.UserOrder - b.UserOrder);

      setUserData(formattedData);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const handleUpdate = (user) => {
    setSelectedUser(user);
    setShowUpdateModal(true);
  };

  const handleDelete = async (userId) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      try {
        const db = firebase.firestore();
        await db.collection("user").doc(userId).delete();
        alert("User deleted successfully!");
        fetchData();
      } catch (error) {
        console.error("Error deleting user: ", error);
        alert("An error occurred while deleting user. Please try again later.");
      }
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      const { id, Date: userDate, ...userDataToUpdate } = selectedUser;
      const db = firebase.firestore();
      const dateTimestamp = firebase.firestore.Timestamp.fromDate(new Date(userDate));
      await db.collection("user").doc(id).update({ ...userDataToUpdate, Date: dateTimestamp });
      alert("User updated successfully!");
      setShowUpdateModal(false);
      fetchData();
    } catch (error) {
      console.error("Error updating user: ", error);
      alert("An error occurred while updating user. Please try again later.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedUser((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const handleClickOutside = (e) => {
    if (updateModalRef.current && !updateModalRef.current.contains(e.target)) {
      setShowUpdateModal(false);
    }
  };

  const handleFilterMonthChange = (e) => {
    setFilterMonth(e.target.value);
  };

  const handleFilterUserIdChange = (e) => {
    setFilterUserId(e.target.value);
  };

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };

  const handleClearUserIdFilter = () => {
    setFilterUserId("");
    fetchData();
  };

  const generateExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(userData.map(({ id, ...rest }) => rest));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "UserData");
    XLSX.writeFile(workbook, "User_Data.xlsx");
  };
  return (
    <>
      <div className="user-data-container">
        <h2>User Data</h2>
        <form onSubmit={handleFilterSubmit}>
          <div className="filter-container">
            <label htmlFor="filterMonth">Filter by Month</label>
            <input
              type="month"
              id="filterMonth"
              value={filterMonth}
              onChange={handleFilterMonthChange}
            />
            <label htmlFor="filterUserId">Filter by User ID</label>
            <input
              type="text"
              id="filterUserId"
              value={filterUserId}
              onChange={handleFilterUserIdChange}
            />
            <button
              className="filter-btn"
              type="button"
              onClick={handleClearUserIdFilter}
            >
              Clear
            </button>
            <button
              className="filter-btn"
              type="button"
              onClick={generateExcel}
            >
              Download Excel
            </button>
          </div>
        </form>
        <table>
          <thead>
            <tr className="tableheaders">
              <th>User No</th>
              <th>ID</th>
              <th>Full Name</th>
              <th>Email</th>
              <th>Contact Number</th>
              <th>Address</th>
              <th>Dealer Name</th>
              <th>Invoice Number</th>
              <th>Littmann Model Purchased</th>
              <th>Color</th>
              <th>Serial Number</th>
              <th>Date (yyyy-mm-dd)</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {userData.map((user) => (
              <tr key={user.id}>
                <td>{user.UserOrder}</td>
                <td>{user.ID}</td>
                <td>{user.FullName}</td>
                <td>{user.Email}</td>
                <td>{user.ContactNo}</td>
                <td>{user.Address}</td>
                <td>{user.DealerName}</td>
                <td>{user.InvoiceNumber}</td>
                <td>{user.LittmannModel}</td>
                <td>{user.Color}</td>
                <td>{user.SerialNumber}</td>
                <td>{user.Date}</td>
                <td>
                  <button
                    className="edit-btn"
                    onClick={() => handleUpdate(user)}
                  >
                    Update
                  </button>
                  <button
                    className="delete-btn"
                    onClick={() => handleDelete(user.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showUpdateModal && selectedUser && (
        <div className="update-modal-container">
          <div className="update-modal" ref={updateModalRef}>
          <h3>Edit User Data</h3>
            <form onSubmit={handleUpdateSubmit}>
              <div className="form-group">
                <label>Full Name</label>
                <input
                  type="text"
                  name="FullName"
                  value={selectedUser.FullName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  name="Email"
                  value={selectedUser.Email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Contact Number</label>
                <input
                  type="tel"
                  name="ContactNo"
                  value={selectedUser.ContactNo}
                  onChange={handleChange}
                  required
                  pattern="[0-9]{10}"
                  title="Please enter a 10-digit contact number"
                />
              </div>
              <div className="form-group">
                <label>Address</label>
                <input
                  type="text"
                  name="Address"
                  value={selectedUser.Address}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Dealer Name</label>
                <input
                  type="text"
                  name="DealerName"
                  value={selectedUser.DealerName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Invoice Number</label>
                <input
                  type="text"
                  name="InvoiceNumber"
                  value={selectedUser.InvoiceNumber}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Littmann Model Purchased</label>
                <input
                  type="text"
                  name="LittmannModel"
                  value={selectedUser.LittmannModel}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Color</label>
                <input
                  type="text"
                  name="Color"
                  value={selectedUser.Color}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Serial Number</label>
                <input
                  type="text"
                  name="SerialNumber"
                  value={selectedUser.SerialNumber}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label>Date</label>
                <input
                  type="date"
                  name="Date"
                  value={selectedUser.Date}
                  onChange={handleChange}
                />
              </div>
              <button className="save-changes-btn" path="/admin" type="submit">
                Save Changes
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default UserData;
